.header {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    gap: 10px;
    gap: 10px 20px; /* row-gap column gap */
    row-gap: 10px;
    column-gap: 20px;
}

.maintitle {
    background-color: white;
    color: black;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
}

.maintitle img {
    vertical-align: middle;
}